import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: resolve => require(['@/views/Home.vue'],resolve)
  },
  {
    path: '/correct',
    name: 'correct',
    component: resolve => require(['@/views/correct.vue'],resolve)
  },
  {
    path: '/incorrect',
    name: 'incorrect',
    component: resolve => require(['@/views/incorrect.vue'],resolve)
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
